@font-face {
  font-family: 'Domaine Text';
  src: url('./assets/fonts/DomaineText-Regular.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Domaine Text';
  src: url('./assets/fonts/DomaineText-Light.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Canela';
  src: url('./assets/fonts/Canela-Black.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Styrene';
  src: url('./assets/fonts/StyreneAWeb-Bold.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Styrene';
  src: url('./assets/fonts/StyreneAWeb-Regular.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Styrene';
  src: url('./assets/fonts/StyreneAWeb-Light.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Apercu';
  src: url('./assets/fonts/Apercu-Medium.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Apercu';
  src: url('./assets/fonts/Apercu-Regular.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Apercu';
  src: url('./assets/fonts/Apercu-Light.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Gentona';
  src: url('./assets/fonts/Gentona-Light.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Gentona';
  src: url('./assets/fonts/Gentona-Book.woff') format('woff');
  font-weight: 300;
}

:root {
  --key-yellow: #3a3733;
  --key-orange: #FC4712;
  --key-red: #FF320C;
  --medium-red: #FF6E54;
  --light-red: #FF9582;
  --darker-medium-brown:#C09993;
  --medium-brown: #DFC7C3;
  --light-brown: #F8EBE9;
  --dark-grey:#9A928F;
  --grey: #CECAC9;
  --light-grey: #EBEAE9;
  --faint-grey: #FAF9F8;
  --notification-green:#90ffc7;
  --notification-medium-green:#6ae4a7;
  --notification-yellow: #fff190;
  --notification-medium-yellow: #f3e37a;
  font-size: 75%;
  --s: 578px;
  --m: 768px;
  --l: 992px;
  --xl: 1200px;
}

:lang(ja) {
  font-weight: 100;
  font-family: sans-serif !important;
};

:lang(ja) .w-main-title-link {
  font-weight: 300;
}

html, body {
    height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Apercu', sans-serif;
  font-weight: 100;
  overflow-x: hidden;
  width: 100vw;
}

p {
  margin: 0;
}

h1 {
  font-weight: 500;
  font-size: 3em;
  padding: 0.5em 0 0.5em 0;
}

.notification {
  opacity: 1;
  text-transform: none;
  font-size: 1.2rem;
  padding: 20px;
  width: fit-content;
  border-radius: 5px;
  margin-bottom: 20px;
  position: absolute;
  z-index: 10000;
}

.notification-green {
    background-color: var(--notification-green);
    border: 1px solid var(--notification-medium-green);
}

.notification-yellow {
    background-color: var(--notification-yellow);
    border: 1px solid var(--notification-medium-yellow);
}

.hidden {
    opacity: 0;
    transition: 1s;
}

.not-hidden {
  opacity: 1;
}

h1.title {
    font-family: 'Canela', serif;
    color: var(--key-red);
    font-size: 4.5rem;
    padding: 0;
}

h2.subtitle {
    font-family: 'Styrene', sans-serif;
    text-transform: uppercase;
    font-size: 3.5rem;
    margin: 0;
    margin-bottom: 30px;
    font-weight: 100;
}


button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

select, option {
  font-family: 'Apercu', sans-serif;
  font-size: 1.5rem;
  padding: 10px;
  border: none;
  box-sizing: content-box;
  border-radius: 1px;
  -webkit-appearance: none;
  background-color: var(--faint-grey);
  border: 1px solid var(--light-grey);
}

select, option :focus {
    outline: none;
}

select {
  background-image: url(./assets/icons/chevron-down.svg);
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  border-radius: 2px;
  padding-right: 2rem;
}


input[type=file]:focus {
   outline: none;
}

input,
textarea {
  font-family: 'Apercu', sans-serif;
  padding: 20px;
  border-radius: 3px;
  border: 1px solid var(--grey);
  font-size: 1.5rem;
  background-image: none;
  box-sizing: border-box;
  max-width: 30vw;
  line-height: 1.5rem;
  height: calc(1.5rem + 40px);
}

input,
textarea::placeholder {
  font-size: 0.8em;
  color: var(--grey);
}

input,
textarea:focus {
  outline: none;
  border: 1px solid black;
}

input[type=file] {
  font-family: 'Apercu', sans-serif;
  padding: 20px;
  border-radius: 3px;
  font-size: 1.5rem;
  cursor: pointer;
}
