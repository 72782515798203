.auth-button-link {
	padding: 10px;
	border: 1px solid black;
	border-radius: 5px;
	color: black;
	font-family: 'Apercu', sans-serif;
	font-weight: 300;
	width: fit-content;
	font-size: 1.6rem;
	margin-left: 25px; 
	text-transform: capitalize;
}
.auth-button-wrapper {
	display: inline-block;
}

.auth0-lock-widget-container {
	font-family: 'Gentona', 'Avenir', sans-serif;
}